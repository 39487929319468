import { useEffect, useState } from "react";
import { HostedModel } from "@runwayml/hosted-models";

const model = new HostedModel({
  url: "https://william-kherbek-82228cb9.hosted-models.runwayml.cloud/v1/",
  token: "Xpgla4LCOGbilSyPJwut/A==",
});

function App() {
  const [seed, setSeed] = useState(Math.floor(Math.random() * (1000 - 1)) + 1);
  const [p, setP] = useState(Math.random());
  const [article, setArticle] = useState("");

  const [charNum, setCharNum] = useState(360);

  const [awake, setAwake] = useState(false);

  const [output, setOutput] = useState("");
  const [finished, setFinished] = useState(true);

  const getWriting = (input) => {
    model
      .query({
        prompt: input,
        max_characters: Number(charNum),
        top_p: parseFloat(p),
        seed: Number(seed),
      })
      .then((outputs) => {
        const { generated_text, encountered_end } = outputs;
        setFinished(encountered_end);
        setOutput(generated_text);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    async function fetchAwake() {
      await model.waitUntilAwake();
      setAwake(true);
    }
    fetchAwake();
  }, []); // Or [] if effect doesn't need props or state

  return (
    <div className="container mx-auto px-12 my-12">
      <h1 className="text-xl">Critical Experiments</h1>
      <div className="pt-4 flex items-center space-x-2">
        <span className="text-sm">Seed: </span>
        <input
          value={seed}
          onChange={(e) => setSeed(e.target.value)}
          type="number"
          min="1"
          max="1000"
          className="border p-2"
        />
      </div>
      <div className="py-2 flex items-center space-x-2">
        <span className="text-sm text-black">Top P: </span>
        <input
          value={p}
          onChange={(e) => setP(e.target.value)}
          type="number"
          step="0.01"
          min="0"
          max="1"
          className="border p-2"
        />
      </div>
      <div className="pb-2 flex items-center space-x-2">
        <span className="text-sm">Number of Characters: </span>
        <input
          value={charNum}
          onChange={(e) => setCharNum(e.target.value)}
          type="number"
          min="8"
          max="1024"
          className="border p-2"
        />
      </div>
      <input
        type="text"
        value={article}
        placeholder="Enter a message"
        onChange={(e) => setArticle(e.target.value)}
        className="border p-2 w-1/3"
      />
      {awake ? (
        <button
          className="p-2 bg-black text-white hover:bg-gray-500"
          onClick={() => {
            getWriting(article);
          }}
        >
          Go
        </button>
      ) : (
        <button className="p-2 bg-black text-white hover:bg-gray-500">
          Waking up...
        </button>
      )}
      <p className="my-10 w-full md:w-1/2">{output}</p>
      {!finished && (
        <button
          className="p-2 bg-black text-white hover:bg-gray-500"
          onClick={() => {
            getWriting(output);
          }}
        >
          Keep going
        </button>
      )}
    </div>
  );
}

export default App;
